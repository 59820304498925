import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Container, Col } from "react-bootstrap"
import PressRoomMasthead from "./press-room-masthead"
import NewsCard from "./news-card"

const News = () => (
  <Layout hero={<PressRoomMasthead active="news" />} showFeature>
    <SEO
      title="In the News | SpaceIt"
      keywords={[
        `broker technology`,
        `commercial real estate`,
        `residential real estate`,
        `commercial real estate technology`,
        `residential real estate technology`,
        `real estate`,
        `spaceit`,
      ]}
    />
    <Container fluid className="px-0 main">
      <Container className="pt-3">
        <Row className="justify-content-center">
          <Col>
            <h2 className="my-3">In the News</h2>
          </Col>
        </Row>
        <Row className="">
          <Col sm={12} md={6} lg={4}>
            <NewsCard />
          </Col>
          {/* <Col sm={12} md={6} lg={4}>
            <NewsCard />
          </Col>
          <Col sm={12} md={6} lg={4}>
            <NewsCard />
          </Col> */}
        </Row>
      </Container>
    </Container>
  </Layout>
)

export default News
