import React from "react"
import Card from "react-bootstrap/Card"
import news from "../../images/press/1686341904-Blank2000x13332.webp"

function NewsCard() {
  return (
    <Card className="news-card">
      <a
        href="https://www.entrepreneur.com/starting-a-business/spaceit-is-transforming-how-real-estate-brokers-do-business/453547"
        target="_blank"
      >
        <Card.Img variant="top" src={news} />
        <Card.Body>
          {/* <Card.Title>Card Title</Card.Title> */}
          <Card.Subtitle className="mb-2 text-muted">
            Entrepreneur
          </Card.Subtitle>
          <Card.Title>
            How the Founders of SpaceIt Are Re-Building the Way Real Estate
            Brokers Do Business
          </Card.Title>
          <Card.Text>
            Read More
          </Card.Text>
        </Card.Body>
      </a>
    </Card>
  )
}

export default NewsCard
